<template>
    <div>
         <Row :gutter="8" class="m-b-10">
      <i-col :xs="24" :sm="12" :md="6"  :lg="4" class="m-b-5">
        <DatePicker placeholder="合同签订时间" v-model="signDate" type="daterange" style="width:100%"></DatePicker>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6"  :lg="4" class="m-b-5">
        <DatePicker placeholder="合同发布时间" v-model="publishDate" type="daterange" style="width:100%"></DatePicker>
      </i-col>
      <i-col v-if="isManage" :xs="24" :sm="12" :md="6"  :lg="4" class="m-b-5">
        <Select v-model="query.companyId" placeholder="所属公司/部门" clearable @on-change="getChannelUserList">
          <Option
            v-for="(channel,index) in channellist"
            :key="index"
            :value="channel.id"
          >{{channel.name}}</Option>
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6"  :lg="4" class="m-b-5">
        <Select v-model="query.userId" placeholder="归属销售" clearable>
          <Option
            v-for="user in channelUsers"
            :key="user.id"
            :value="user.id"
          >{{ user.name}}</Option>
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6"  :lg="4" class="m-b-5">
        <i-select placeholder="状态" v-model="query.status" multiple>
          <i-option value="-1">已废弃</i-option>
          <i-option value="0">草稿</i-option>
          <i-option value="4">待审批</i-option>
          <i-option value="5">已通过</i-option>
          <i-option value="6">已拒绝</i-option>
          <!-- <i-option value="9">已归档</i-option> -->
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6"  :lg="4" class="m-b-5">
        <i-select placeholder="合同文件状态" clearable v-model="query.sealStatus">
          <i-option value="0">未出</i-option>
          <i-option value="1">已出</i-option>
          <i-option value="2">已归档</i-option>
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6"  :lg="4" class="m-b-5">
        <i-select placeholder="合同标记" clearable v-model="query.visibleScope">
          <i-option value="1">特殊标记</i-option>
          <i-option value="2">默认</i-option>
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6"  :lg="4" class="m-b-5">
        <i-input placeholder="关键字：支持名称查询" v-model="query.keyword"></i-input>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6"  :lg="4" class="m-b-5">
        <i-button class="m-r-10" type="primary" icon="ios-search" long @click="handleSearch()">搜索</i-button>
        <!-- <i-button  icon="md-cloud-download" type="info" >导出Excel</i-button> -->
      </i-col>
       <i-col :xs="24" :sm="12" :md="6"  :lg="4" class="m-b-5">
        <!-- <i-button class="btn-primary m-r-10" icon="ios-search" @click="handleSearch()">搜索</i-button> -->
        <i-button   type="success"  style="width:100%" @click="handleExportExcel">导出Excel</i-button>
      </i-col>
    </Row>

    <Table stripe :data="list" :columns="tableColumns"></Table>
    <div class="paging_style">
      <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator :current="query.pageNumber" @on-change="handlePageChanged" ></Page>
    </div>

    </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { GetPublishDay, GetCurrentSchedule } from '@/utils/dateFormat'
import { getCompanyPage } from '@/api/os/company'
import { getSellerPage } from '@/api/os/companyuser'
import { getContractPage } from '@/api/scp/contract'
import { downloadFileRequest } from '@/utils/download'
import { formatContractStatus, formatContractRemark } from '@/utils/tagStatus'

export default {
  props: {
    params: Object
  },
  data () {
    return {
      taskTypes: [],
      currentTypeId: 1,

      channellist: [],
      channelUsers: [],
      signDate: [],
      publishDate: [],
      total: 0,
      publisherId: this.$store.getters.token.userInfo.publisherId,

      query: {
        pageNumber: 1,
        pageSize: 15,
        keyword: '',
        userId: '',
        companyId: null,
        startDate: '',
        endDate: '',
        status: [],
        sealStatus: null,
        visibleScope: null
      },
      list: [],
      tableColumns: [
        {
          title: '合同编号',
          key: 'code'
        },
        {
          title: '合同名称',
          key: 'name'
        },
        {
          title: '所属代理商/销售',
          key: 'companyName',
          render: (h, params) => {
            return h('div', [
              h(
                'p',
                params.row.companyName
              ),
              h(
                'p',
                params.row.userName
              )
            ])
          }
        },
        {
          title: '合同起止日',
          key: 'startDate',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                this.formatSchuedle(params.row).value
              )
            ])
          }
        },
        {
          title: '合同总额',
          key: 'amount',
          render: (h, params) => {
            return h('div', [
              h(
                'strong',
                {
                  attrs: {
                    style: 'color:#FA8B59'
                  }
                },
                toMoney(params.row.amount)
              )
            ])
          }
        },
        {
          title: '已收款',
          key: 'receivedAmount',
          render: (h, params) => {
            return h('div', [
              h(
                'strong',
                {
                  attrs: {
                    style: 'color:#FA8B59'
                  }
                },
                toMoney(params.row.receivedAmount)
              )
            ])
          }
        },
        {
          title: '备注',
          render: (h, data) => {
            return formatContractRemark(h, data.row)
          }
        },
        {
          title: '状态',
          width: 120,
          render: (h, data) => {
            return formatContractStatus(h, data.row.status, data.row.statusName)
          }
        },
        {
          title: '合同文件标记',
          key: 'sealStatusName'
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'a', {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.handleShowDetail(params.row.id)
                    }
                  }
                }, '详情'
              ), h(
                'a', {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.handleEdit(params.row.id)
                    }
                  }
                }, '设置'
              )
            ])
          }
        }
      ]
    }
  },
  created () {
    this.initPage()
  },
  computed: {
    isManage () {
      return this.channellist.length > 1
    }
  },
  methods: {

    formatSchuedle (data) {
      return {
        value: GetCurrentSchedule(data.startDate, data.endDate),
        days: GetPublishDay(data.startDate, data.endDate)
      }
    },
    formatMoney (number) {
      return toMoney(number)
    },
    handleShowDetail (id) {
      this.$emit('on-showDetail', id)
    },
    handleEdit (id) {
      this.$emit('on-setting', id)
    },

    reload () {
      this.getPage()
    },
    initPage () {
      const channelquery = {
        pageNumber: 1,
        pageSize: 100,
        type: JSON.stringify([2])
      }
      getCompanyPage(channelquery).then(res => {
        this.channellist = res.list
        this.getChannelUserList()
      })

      this.getPage()
    },
    getChannelUserList () {
      this.query.companyId = this.isManage ? this.query.companyId : this.$store.getters.token.userInfo.companyId
      if (this.query.companyId) {
        const data = {
          companyId: this.query.companyId,
          pageNumber: 1,
          pageSize: 1000
        }
        getSellerPage(data).then(response => {
          this.channelUsers = response.list
        })
      } else {
        this.channelUsers = []
        this.query.userId = ''
      }
    },
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.getPage()
    },
    handleSearch () {
      this.query.pageNumber = 1

      this.query.startDate = this.formatDate(this.publishDate[0])
      this.query.endDate = this.formatDate(this.publishDate[1])
      this.query.signStartDate = this.formatDate(this.signDate[0])
      this.query.signEndDate = this.formatDate(this.signDate[1])
      this.getPage()
    },
    formatDate (date) {
      if (!date) {
        return null
      } else {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      }
    },
    getPage () {
      if (this.query.status.length < 1) {
        this.query.statusList = JSON.stringify([-1, 4, 5, 6, 9])
      } else {
        this.query.statusList = JSON.stringify(this.query.status)
      }
      getContractPage(this.query).then(res => {
        res.list.map(item => {
          item.visible = (item.visibleScope === 2)
          return item
        })
        this.list = res.list
        this.total = res.totalRow
      })
    },
    changeTaskType (id) {
      this.currentTypeId = id
    },
    handleExportExcel () {
      if (this.query.status.length < 1) {
        this.query.statusList = JSON.stringify([-1, 4, 5, 6, 9])
      } else {
        this.query.statusList = JSON.stringify(this.query.status)
      }
      const query = Object.assign({}, this.query, { subjectTypeList: JSON.stringify([1, 3]) })
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v1/contractfile/exportexcel', query, '合同列表.xlsx')
    }
  }
}
</script>
